import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrl: './filter-menu.component.scss',
})
export class FilterMenuComponent {
  @Input() formGroup!: FormGroup;

  searchParams: any = {};

  selectedFilters = {
    brandSizeId: [],
    brandAutoPilotId: [],
    brandSubIndustryId: [],
  };

  minValue = 0;
  maxValue = 10000000000;

  constructor(public activeModal: NgbActiveModal, private api: ApiService) {}

  ngOnInit(): void {
    this.getSearchParams();
    this.minValue = this.formGroup.value.priceMin || this.searchParams.priceMin || 0;
    this.maxValue = this.formGroup.value.priceMax || this.searchParams.priceMax || 10000000000;
    if (this.formGroup.value.brandSizeId.length > 0) {
      this.formGroup.value.brandSizeId.forEach((size: any) => {
        this.isSelected('brandSizeId', size);
      });
    }
    if (this.formGroup.value.brandAutoPilotId.length > 0) {
      this.formGroup.value.brandAutoPilotId.forEach((type: any) => {
        this.isSelected('brandAutoPilotId', type);
      });
    }
    if (this.formGroup.value.brandSubIndustryId.length > 0) {
      this.formGroup.value.brandSubIndustryId.forEach((industry: any) => {
        this.isSelected('brandSubIndustryId', industry);
      });
    }
  }

  getSearchParams() {
    this.api.getFilterOptions().subscribe({
      next: (response: any) => {
        this.searchParams = response.data;
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  toggleSelection(e: any, filterType: string, value: any) {
    e.preventDefault();
    const selectedValues = this.formGroup.get(filterType)?.value || [];
    if (selectedValues.find((v: any) => v.id === value.id)) {
      this.formGroup.get(filterType)?.setValue(selectedValues.filter((v: any) => v.id !== value.id));
    } else {
      this.formGroup.get(filterType)?.setValue([...selectedValues, value]);
    }
  }

  isSelected(filterType: string, value: any): boolean {
    return this.formGroup.get(filterType)?.value.some((v: any) => v.id === value.id);
  }

  updateMinValue(event: any) {
    this.minValue = event.target.value;
    this.formGroup.get('priceMin')?.setValue(this.minValue);
  }

  updateMaxValue(event: any) {
    this.maxValue = event.target.value;
    this.formGroup.get('priceMax')?.setValue(this.maxValue);
  }

  resetFilters() {
    this.formGroup.reset({
      search: this.formGroup.value.search,
      brandSizeId: [],
      brandAutoPilotId: [],
      brandSubIndustryId: [],
      priceMin: 0,
      priceMax: 10000000000,
      sortBy: '',
    });
  }

  applyFilters() {
    this.activeModal.close(this.formGroup.value);
  }

  closeOverlay() {
    this.activeModal.dismiss();
  }

  hasActiveFilters(): boolean {
    return (
      this.formGroup.get('brandSizeId')?.value.length > 0 ||
      this.formGroup.get('brandAutoPilotId')?.value.length > 0 ||
      this.formGroup.get('brandSubIndustryId')?.value.length > 0 ||
      this.formGroup.get('priceMin')?.value > 0 ||
      this.formGroup.get('priceMax')?.value < 10000000000
    );
  }

}
