import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.scss']
})
export class SortMenuComponent {
  @Input() formGroup!: FormGroup;

  sortBy: any[] = [
    { id: 'packageStartFromAsc', name: 'Price Ascending' },
    { id: 'packageStartFromDesc', name: 'Price Descending' },
    { id: 'nameAsc', name: 'Brand Name Ascending' },
    { id: 'nameDesc', name: 'Brand Name Descending' },
  ];

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (this.formGroup.value.sortBy) {
      this.formGroup.get('sortBy')?.setValue(this.formGroup.value.sortBy);

    }
  }

  onChangeSortBy(event: any) {
    const selectedValue = event.target.value;
    this.formGroup.get('sortBy')?.setValue(selectedValue);
    this.applySorts();
  }

  resetSorts() {
    this.formGroup.reset({
      search: '',
      brandSizeId: [],
      brandAutoPilotId: [],
      brandSubIndustryId: [],
      priceMin: '',
      priceMax: '',
      sortBy: ''
    });
  }

  applySorts() {
    this.activeModal.close(this.formGroup.value);
  }

  closeOverlay() {
    this.activeModal.dismiss();
  }
}
