<div class="modal-header bg-primary text-white">
  <h5 class="modal-title" id="filterMenuModalLabel">Filter By</h5>
  <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <form (submit)="applyFilters();">
    <div class="mb-4">
      <h6>Business Annual Revenue</h6>
      <span *ngFor="let size of searchParams?.brandSize">
        <button
          class="btn btn-sm me-2 mb-2 rounded-pill"
          [ngClass]="{'btn-outline-primary': isSelected('brandSizeId', size), 'btn-outline-light text-dark': !isSelected('brandSizeId', size)}"
          (click)="toggleSelection($event, 'brandSizeId', size)"
        >{{ size.name }}</button>
      </span>
    </div>
    <div class="mb-4">
      <h6>Business Type</h6>
      <span *ngFor="let type of searchParams?.brandAutoPilot">
        <button
          class="btn btn-sm me-2 mb-2 rounded-pill"
          [ngClass]="{'btn-outline-primary': isSelected('brandAutoPilotId', type), 'btn-outline-light text-dark': !isSelected('brandAutoPilotId', type)}"
          (click)="toggleSelection($event, 'brandAutoPilotId', type)"
        >{{ type.name }}</button>
      </span>
    </div>
    <div class="mb-4">
      <h6>Sub Industry</h6>
      <span *ngFor="let industry of searchParams?.brandSubIndustry">
        <button
          class="btn btn-sm me-2 mb-2 rounded-pill"
          [ngClass]="{'btn-outline-primary': isSelected('brandSubIndustryId', industry), 'btn-outline-light text-dark': !isSelected('brandSubIndustryId', industry)}"
          (click)="toggleSelection($event, 'brandSubIndustryId', industry)"
        >{{ industry.name }}</button>
      </span>
    </div>
    <div class="mb-4">
      <h6>Price Range</h6>
      <div class="mb-3">
        <div class="input-group mb-3">
          <span class="input-group-text" id="minPrice"><small>Min Price</small></span>
          <input aria-describedby="minPrice"
                aria-label="Min Price"
                placeholder="Min Price"
                type="number"
                class="form-control form-control-sm"
                [(value)]="minValue"
                (change)="updateMinValue($event)"
          />
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="maxPrice"><small>Max Price</small></span>
          <input aria-describedby="maxPrice"
                aria-label="Max Price"
                placeholder="Max Price"
                type="number"
                class="form-control form-control-sm"
                [(value)]="maxValue"
                (change)="updateMaxValue($event)"
          />
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-primary d-none" data-bs-dismiss="modal" (click)="applyFilters()">Filter</button>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetFilters()">Reset</button>
  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="applyFilters()">Filter</button>
</div>