import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private refreshSubject = new Subject<void>();
  refresh$ = this.refreshSubject.asObservable();

  private searchQuerySubject = new BehaviorSubject<string>('');
  searchQuery$ = this.searchQuerySubject.asObservable();

  triggerRefresh() {
    this.refreshSubject.next();
  }

  setSearchQuery(query: string) {
    this.searchQuerySubject.next(query);
  }

  clearSearchQuery() {
    this.searchQuerySubject.next('');
  }
}
